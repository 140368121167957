@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
:root {
    --primary-white: #ffffff;
    --primary-black: #000000;
    --primary-green: rgb(0, 179, 0);
    --primary-light-grey: #f4f4f4;
    --primary-grey: #ccd1d9;
    --primary-dark-grey: #666666;
    --primary-orange: #f99500;
    --primary-dark: rgb(52, 58, 64);
    --primary-red: #ef4035;
    --primary-hover-red: #f33501;
}
body {
    font-family: "Roboto Condensed", sans-serif !important;
}
/*****************NAVBAR******************/
nav {
    z-index: 2;
}
.logo {
    width: 16.25rem;
}

/*-----Menu Links-----*/

nav a.nav-link {
    /* font-weight: 500; */
    letter-spacing: 0.0375rem;
    cursor: pointer;
    white-space: nowrap;
}

nav ul li {
    text-transform: uppercase;
}

.navbar-nav > li > a {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
    color: #ffffff !important;
    color: var(--primary-white) !important;
    border-top: 0.1875rem solid rgb(52, 58, 64);
    border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
    max-width: 17.25rem;
    padding: 0 !important;
    height: 6rem !important;
}

.navbar-brand .logo {
    margin-top: 1.2rem;
    max-width: 20rem;
}

@media (max-width: 768px) {
    .navbar-brand {
        width: 7rem;
    }
}
@media (max-width: 400px) {
    .navbar-brand .logo {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
        margin-left: -10px;
    }
}
@media (max-width: 350px) {
    .navbar-brand .logo {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        margin-left: -20px;
    }
}

@media (max-width: 325px) {
    .navbar-brand .logo {
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
        margin-left: -40px;
    }
}

@media (max-width: 300px) {
    .navbar-brand .logo {
        -webkit-transform: scale(0.6);
                transform: scale(0.6);
        margin-left: -50px;
    }
}

.navbar-nav > li > a.active,
.navbar-nav > li > a.active:focus {
    border-top: 0.1875rem solid #f99500;
    border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
    border-top: 0.1875rem solid #f99500;
    border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-toggler {
    color: #ffffff !important;
    color: var(--primary-white) !important;
}

.animated-icon2 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.animated-icon2 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
}
.animated-icon2 span {
    background: #e3f2fd;
}

.animated-icon2 span:nth-child(1) {
    top: 0px;
}

.animated-icon2 span:nth-child(2),
.animated-icon2 span:nth-child(3) {
    top: 10px;
}

.animated-icon2 span:nth-child(4) {
    top: 20px;
}

.animated-icon2.open span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
}

.animated-icon2.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
}

/*****************HEADER******************/
.header-wraper {
    position: relative;
    background: url(../../static/media/wall-and-laptop-background.f9e274f4.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.main-info {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;
}

.main-info h3 {
    color: #f99500;
    color: var(--primary-orange);
    text-transform: uppercase;
}

/*-----Typed Text-----*/
.typed-text {
    color: #ffffff;
    color: var(--primary-white);
}

/*-----Main Offer Button-----*/
.btn-main-offer {
    border: 1px solid #ef4035;
    border: 1px solid var(--primary-red);
    text-transform: uppercase;
    border-radius: 0;
    padding: 0.625rem 0.75rem 0.75rem 0.75rem;
    color: #ffffff;
    color: var(--primary-white);
    margin: 2rem;
}

.btn-main-offer:hover {
    text-decoration: none;
    background-color: #f33501;
    background-color: var(--primary-hover-red);
    color: #ffffff;
    color: var(--primary-white);
    transition: 0.2s ease-in-out;
}

/*-----Particles-----*/
.tsparticles-canvas-el {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1;
}

@media (max-width: 768px) {
    .particles-canvas {
        display: none;
    }
}

/*****************AboutMe******************/
.about .container {
    margin-top: 1rem;
}
.photo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-img {
    width: 12rem;
    height: 12rem;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    border-radius: 50%;
    object-fit: contain;
    padding: 0.3rem;
    border: 1px solid #f99500;
    border: 1px solid var(--primary-orange);
    /* transform: scale(2); */
}

.about-heading {
    color: #f99500;
    color: var(--primary-orange);
    text-transform: uppercase;
    text-align: center;
    padding: 0 0 2.6rem 0;
}

@media (max-width: 992px) {
    .profile-img {
        margin-bottom: 40px;
    }
}
@media (max-width: 766px) {
    .profile-img {
        -webkit-transform: scale(1.25);
                transform: scale(1.25);
        margin-bottom: 20px;
    }
}
@media (max-width: 400px) {
    .profile-img {
        -webkit-transform: scale(1);
                transform: scale(1);
        margin-bottom: 0px;
    }
}

/*****************Skills******************/
.skills {
    text-align: center;
    padding-bottom: 5rem;
    padding-top: 1rem;
    background: #f4f4f4;
    background: var(--primary-light-grey);
}

.column {
    float: left;
}

.skills .container {
    position: relative;
}

.skills h1 {
    color: #f99500;
    color: var(--primary-orange);
    text-transform: uppercase;
}

.skills-list {
    list-style-type: none;
    margin: 0 2.6rem 0 0;
}

.skills-list:hover {
    color: #ffffff;
    color: var(--primary-white);
}

.skills-box:hover {
    color: #ffffff;
    color: var(--primary-white);
}

.skills .circle {
    position: relative;
    margin: 0.375rem auto;
    background: #f99500;
    background: var(--primary-orange);
    border-radius: 50%;
    width: 3.125rem;
    height: 3.125rem;
}

.skills .icon {
    font-size: 1.4rem;
    color: #ffffff;
    color: var(--primary-white);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.skills .box {
    height: 16rem;
    border-bottom: 0.3125rem solid #f99500;
    border-bottom: 0.3125rem solid var(--primary-orange);
    padding: 0.625rem;
    margin-bottom: 1.875rem;
    background: #ffffff;
    background: var(--primary-white);
    transition: 0.3s ease-in-out;
}

.skills .box:hover {
    background: #f99500;
    background: var(--primary-orange);
    border-bottom: 0.3125rem solid rgb(52, 58, 64);
    border-bottom: 0.3125rem solid var(--primary-dark);
}

.skills .box:hover .icon {
    color: rgb(52, 58, 64);
    color: var(--primary-dark);
}

.skills .box:hover .circle {
    background: #ffffff;
    background: var(--primary-white);
}

.skills .box:hover p {
    color: #ffffff;
    color: var(--primary-white);
}

/*****************Experience******************/
.experience {
    padding: 0.7rem;
    margin: 1rem 0 5rem 0;
}
.experience-wrapper {
    position: relative;
    padding: 3.125rem 0;
    overflow: hidden;
}

.experience h1 {
    text-transform: uppercase;
    color: #f99500;
    color: var(--primary-orange);
}

.experience-wrapper::before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    margin-left: -0.0625rem;
    width: 0.125rem;
    height: 100%;
    background: #ccd1d9;
    background: var(--primary-grey);
    z-index: 1;
}

.timeline-block {
    width: calc(50% + 0.5rem);
    display: flex;
    justify-content: space-between;
    clear: both;
}

.timeline-block-right {
    float: right;
}

.timeline-block-left {
    float: left;
    direction: rtl;
}

.marker {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 0.3125rem solid rgb(52, 58, 64);
    border: 0.3125rem solid var(--primary-dark);
    background: #f99500;
    background: var(--primary-orange);
    margin-top: 0.625rem;
    z-index: 3;
}

.timeline-content {
    width: 95%;
    padding: 0 0.9375rem;
    color: #666666;
    color: var(--primary-dark-grey);
    direction: ltr;
}

.timeline-content h3 {
    margin-top: 0.3125rem;
    color: #ef4035;
    color: var(--primary-red);
    font-weight: 900;
}

@media (max-width: 768px) {
    .experience .container {
        padding-left: 0;
    }
    .experience-wrapper::before {
        left: 0.5rem;
        margin-bottom: 1.875rem;
    }
    .timeline-block-right {
        float: none;
        width: 100%;
    }
    .timeline-block-left {
        float: none;
        direction: ltr;
        width: 100%;
    }
}

/*****************Portfolio******************/
.portfolio-wrapper {
    background: #f4f4f4;
    background: var(--primary-light-grey);
    padding: 2rem 0;
    padding-bottom: 5rem;
}

.portfolio-wrapper h1 {
    color: #f99500;
    color: var(--primary-orange);
}

.portfolio-image {
    width: 15rem;
    border: 1px solid #f99500;
    border: 1px solid var(--primary-orange);
    padding: 0.5rem 0.5rem;
}

.portfolio-image-box {
    position: relative;
    margin: 1rem;
}

/*-----Overflow-----*/
.overflow {
    position: absolute;
    opacity: 0;
    background: #000000;
    background: var(--primary-black);
    width: 15rem;
    height: 9rem;
    top: 0;
    border: 1px solid #ef4035;
    border: 1px solid var(--primary-red);
    cursor: pointer;
}

.portfolio-image-box:hover .overflow {
    opacity: 0.6;
    transition: 0.3s ease-in-out;
}

.portfolio-icon {
    position: absolute;
    opacity: 0;
    margin-left: -8rem;
    margin-top: 3.6rem;
    color: #f99500;
    color: var(--primary-orange);
    font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
    opacity: 0.7;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

/*-----Overflow-----*/
.portfolio-image-popupbox {
    width: 45rem;
    padding: 0 0.5rem;
    margin: 0 0 0 0;
}
.popupbox-titleBar {
    margin: 1rem 0 0 0;
}
.popupbox-wrapper {
    width: 50%;
    margin-top: 6rem;
    margin-bottom: 2rem;
}

.hyper-link {
    cursor: pointer;
    color: #f99500;
    color: var(--primary-orange);
}

.hyper-link:hover {
    color: #ef4035;
    color: var(--primary-red);
}

/*-----Media-----*/
@media (max-width: 768px) {
    .portfolio-image-popupbox {
        width: 100%;
    }
    .popupbox-wrapper {
        margin-top: 6rem;
        margin-bottom: -1rem;
    }
}

@media (max-height: 570px) {
    .popupbox-wrapper {
        height: 80% !important;
    }
    .portfolio-image-popupbox {
        width: 100%;
    }
    .popupbox-wrapper {
        margin-top: 6rem;
        margin-bottom: 2rem;
    }
}

@media (max-height: 280px) {
    .popupbox-wrapper {
        height: 70% !important;
    }
    .portfolio-image-popupbox {
        width: 30%;
    }
}

/*****************Contacts******************/
.contacts {
    background: rgb(52, 58, 64);
    background: var(--primary-dark);
    padding: 3rem 0;
}

.contacts h1 {
    color: #f99500;
    color: var(--primary-orange);
    padding: 1.3rem 0;
    text-transform: uppercase;
}

.contacts p {
    color: #f4f4f4;
    color: var(--primary-light-grey);
    font-size: 1.2rem;
}

/*-----Inputs-----*/
.contacts input,
.contacts textarea {
    width: 100%;
    box-sizing: border-box;
    background: none;
    resize: none;
    border: 0;
    border-bottom: 0.125rem solid #666666;
    border-bottom: 0.125rem solid var(--primary-dark-grey);
    border-radius: 0;
    color: #f99500;
    color: var(--primary-orange);
    outline: 0 !important;
    box-shadow: none !important;
    margin-bottom: 1rem;
    padding-left: 0 !important;
}

.contacts .container {
    padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
    background: transparent;
    color: #f99500;
    color: var(--primary-orange);
    border-bottom: 0.125rem solid #666666;
    border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
    height: 10.9rem !important;
}

.contact-btn {
    margin: 1rem 0 0 0;
    width: 100%;
    background: none;
}

.line {
    position: relative;
    top: -0.8rem;
    width: 0;
    height: 0.125rem;
    background: #f99500;
    background: var(--primary-orange);
    display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
    width: 100%;
    transition: width 0.5s ease-in-out;
}

input.form-control {
    margin: -0.3rem 0;
}

textarea.form-control {
    margin: -0.3rem 0;
}

.contacts .container .row .error-message {
    color: #ffffff;
    color: var(--primary-white);
    background: #ef4035;
    background: var(--primary-red);
    text-align: left;
    /* margin: -20px 0px 7500px 0px; */
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
}

.success-message {
    font-weight: 900;
    color: rgb(0, 179, 0);
    color: var(--primary-green);
    padding: 0rem 0.2rem 0rem 0.2rem;
}

.text-center {
    margin: 0rem 0rem 0rem 0rem;
}

/*-----Media-----*/
@media (max-width: 768px) {
    .contacts {
        overflow-x: hidden;
    }
    .contacts .container {
        padding: 0 1rem;
    }

    .contacts p {
        padding: 0 0.4rem;
    }
}

/*****************Footer******************/
.footer {
    background: #000000;
    background: var(--primary-black);
    color: #666666;
    color: var(--primary-dark-grey);
    padding: 3rem;
}

.footer a {
    text-decoration: none;
    color: #666666;
    color: var(--primary-dark-grey);
}

.footer a:hover {
    text-decoration: none;
    color: #f99500;
    color: var(--primary-orange);
    cursor: pointer;
}

.footer p {
    margin-bottom: 0;
}

.footer .col {
    margin: 0 1rem 0 1rem;
    white-space: nowrap;
}

